<template>
  <div
    class="relative min-h-[230px] md:min-h-[390px] w-full border-y border-[#D9D9D9]"
  >
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :modules="[Pagination, Navigation, Autoplay]"
      :pagination="{ clickable: true }"
      :navigation="true"
      :grab-cursor="true"
      :speed="300"
      :autoplay="{ delay: 3000 }"
      :long-swipes-ratio="0.15"
      @progress="onProgress"
      @set-transition="onSetTransition"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index"
        class="!flex flex-col justify-center items-center min-h-[230px] md:min-h-[390px]"
      >
        <p>{{ slide[0] }}</p>
        <p
          :style="{
            transform: `translateX(${200 * slideProgress(index)}%`,
            transitionDuration: `${transitionDuration}ms`,
          }"
        >
          {{ slide[1] }}
        </p>
      </swiper-slide>
    </swiper>
    <div
      class="absolute inset-0 z-10 pointer-events-none flex items-center justify-center"
    >
      <TwoSpheres class="opacity-20" :colors="['#EB3DFB', '#0BB7C2']" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  default as SwiperInstance,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { ref } from "vue";
import TwoSpheres from "./TwoSpheres.vue";

const offset = ref(0);
const slides = [
  ["Think beyond", "one-time purchase"],
  ["Add recurring", "to your revenue"],
  ["Innovate and sustain", "growth with Juo"],
];
const transitionDuration = ref(0);

function slideProgress(index: number) {
  const perSlide = 1 / (slides.length - 1);
  const min = index * perSlide;

  return (1 * (offset.value - min)) / perSlide;
}

function onProgress(_swiper: SwiperInstance, value: number) {
  offset.value = value;
}

function onSetTransition(_swiper: SwiperInstance, value: number) {
  transitionDuration.value = value;
}
</script>

<style lang="postcss" scoped>
p {
  @apply w-full font-[750] text-[22px] leading-[30px] text-center md:text-[46px] md:leading-[56px];
}

.swiper:after {
  @apply absolute inset-0 pointer-events-none z-10;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 1)
  );
  content: "";
}

.swiper-slide p {
  @apply transition-transform;
}

.swiper-slide-prev p + p {
  @apply translate-x-[200%];
}

.swiper-slide-next p + p {
  @apply translate-x-[200%];
}

.swiper:deep(.swiper-button-prev),
.swiper:deep(.swiper-button-next) {
  @apply md:w-[130px] h-full absolute top-0 z-10 cursor-pointer;
}
.swiper:deep(.swiper-button-prev.swiper-button-disabled),
.swiper:deep(.swiper-button-next.swiper-button-disabled) {
  @apply cursor-auto;
}
.swiper:deep(.swiper-button-prev) {
  @apply left-0;
}
.swiper:deep(.swiper-button-next) {
  @apply right-0;
}

.swiper:deep(.swiper-pagination-bullets) {
  @apply flex justify-center bottom-6 md:bottom-[38px];
}

.swiper:deep(.swiper-pagination-bullet) {
  @apply block bg-[#D9D9D9] opacity-100 mx-[5px] w-[6px] h-[6px];
}

.swiper:deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply bg-[#070707];
}
</style>
